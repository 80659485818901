import 'intersection-observer';

/**!
 * Lazyload v1.0.1.20191102
 */
export default class Lazyload {

	observerConfig = {
		root: null, rootMargin: `300px 0px`, threshold: [0],
	};

	options = {
		once: true,
	};
	observer = null;
	timerIds = {};

	constructor(options = {}) {
		this.observerConfig = {root: options.options || this.observerConfig.root, rootMargin: options.rootMargin || this.observerConfig.rootMargin, threshold: options.threshold || this.observerConfig.threshold};
		this.options = {...this.options, ...options};
		this.observe = this.observe.bind(this);
		this.mountObserver();
	}

	observe(item) {
		if (Object.prototype.toString.call(item) === "[object HTMLDivElement]" && this.observer !== null) {
			this.observer.observe(item);
		}
	}

	mountObserver() {

		if (this.observer !== null) return;

		this.observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				let lazytarget = entry.target.getAttribute('data-lazytarget');
				if (this.timerIds[lazytarget]) {
					clearTimeout(this.timerIds[lazytarget]);
				}
				if (!this.timerIds[lazytarget]) {
					setTimeout(() => {
						if (entry.isIntersecting > 0) {
							if (this.options.once) {
								this.observer.unobserve(entry.target);
							}
							let lazyloadCallback = new Function(entry.target.getAttribute('data-lazyload'));
							lazyloadCallback && lazyloadCallback();
						}
					}, 300);
				}
			});
		}, this.observerConfig);
	}


}
